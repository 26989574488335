import { createGlobalStyle } from "styled-components";
import PaytrieRegularFont from "./assets/PPObjectSans-Regular.otf";
import PaytrieBoldFont from "./assets/PPObjectSans-Bold.otf";
import PaytrieMediumFont from "./assets/PPObjectSans-Medium.otf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'PP Object Sans';
    src: url(${PaytrieRegularFont}) format("opentype");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PP Object Sans';
    src: url(${PaytrieMediumFont}) format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PP Object Sans';
    src: url(${PaytrieBoldFont}) format("opentype");
    font-weight: 700;
    font-style: normal;
  }
  
  html {
    font-size: 1rem;
    height: 100%;
  }
`;

export default GlobalStyle;
