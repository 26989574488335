import { FormEvent, useState } from "react";
import parsePhoneNumber from "libphonenumber-js";
import * as Toast from "@radix-ui/react-toast";
import { useNavigate } from "react-router-dom";
import ReactSpinnerTimer from "react-spinner-timer";

import { Button } from "@/components/Button/Button";
import { LoadingIcon } from "@/components/LoadingIcon";
import {
  ErrorBlockText,
  TextInput,
  LeftAlignSmallText,
} from "@/components/widget-components";
import { verifySMS, sendSMS, updateUserCurrentFlow } from "@/requests/signup";
import { useSignupStore } from "@/reducers";
import { submitOnEnter } from "@/utils/keyboard";
import { FormStates } from "./FormStateMachine";

export default function SignupContentVerifyPhoneNumber() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPhoneNumberPin] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>("");
  const [clicked, setClicked] = useState(false);
  const [codeSentSuccess, setCodeSentSuccess] = useState(true);
  const [open, setOpen] = useState(false);
  const email = useSignupStore.use.email();
  const setFormState = useSignupStore.use.nextState();
  const phoneNumber = useSignupStore.use.phoneNumber();
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "CA");

  const resendCode = async () => {
    setIsLoading(true);
    sendSMS(email, phoneNumber)
      .then(() => {
        setCodeSentSuccess(true);
        setClicked(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setCodeSentSuccess(false);
        setClicked(true);
        setIsLoading(false);
      });
  };

  const updateSpinTimer = (lap: any) => {
    if (lap.isFinished) {
      setClicked(false);
    } else {
      if (lap.actualLap === lap.totalLaps) {
        setClicked(false);
      }
    }
  };

  async function sendPhoneNumberPin(e: FormEvent): Promise<void> {
    e.preventDefault();
    if (pin.length < 4) {
      return setErrorMessage("Invalid pin");
    }
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [err, _] = await verifySMS(email, pin);
    if (err === null) {
      setErrorMessage("");
      setFormState();
      // set flow
      await updateUserCurrentFlow(email, FormStates.VERIFY_PHONE);
      navigate("/SignupDisclosure");
    } else if (err === "Failed too many attempts. New SMS code sent") {
      setErrorMessage("Failed too many attempts. New SMS code sent");
    } else if (err === "Mismatched verification code") {
      setErrorMessage(
        "The verification code you entered does not match. Please check the code and try again."
      );
    } else {
      setErrorMessage(
        "Unfortunately we were unable to process your verification at this time. Please try again later or contact us at support@paytrie.com"
      );
    }
  }

  return (
    <form onSubmit={sendPhoneNumberPin}>
      <div className="flex flex-col gap-6">
        <div className="text-lg font-normal uppercase">Verify Phone Number</div>
        <div className="text-base">
          Enter the code we sent to{" "}
          {parsedPhoneNumber
            ? parsedPhoneNumber.formatNational()
            : "(000) 000-0000"}
        </div>
        <TextInput
          autoFocus
          placeholder="Verification code"
          type="tel"
          min={0}
          onChange={(e) =>
            setPhoneNumberPin(
              e.target.value.replace(/[^0-9\+]/g, "").substring(0, 4)
            )
          }
          onKeyDown={submitOnEnter(sendPhoneNumberPin)}
          value={pin}
        />
        <div className="mb-[-50px]">
          <LeftAlignSmallText className="flex">
            <Toast.Provider swipeDirection="right">
              <button
                className="codeButton"
                type="button"
                style={{ float: "left", textDecoration: "underline" }}
                disabled={clicked}
                onClick={() => {
                  setOpen(true);
                  resendCode();
                }}
              >
                Resend code
              </button>
              <Toast.Root
                className="ToastRoot"
                open={open}
                onOpenChange={setOpen}
              >
                {codeSentSuccess && (
                  <Toast.Title className="ToastTitle">Code sent</Toast.Title>
                )}
                {!codeSentSuccess && (
                  <Toast.Title className="ToastTitle">
                    Unable to send code
                  </Toast.Title>
                )}
              </Toast.Root>
              <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
            {clicked && (
              <div style={{ scale: "0.5" }}>
                <ReactSpinnerTimer
                  timeInSeconds={60}
                  totalLaps={1}
                  onLapInteraction={updateSpinTimer}
                  isPaused={false}
                />
              </div>
            )}
          </LeftAlignSmallText>
        </div>
        <ErrorBlockText>{errorMessage}</ErrorBlockText>
        <Button
          className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
          type="submit"
          disabled={pin.length < 4}
        >
          Continue
        </Button>
      </div>
    </form>
  );
}
