import { useState } from "react";
import Iframe from "react-iframe";

import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

export const DashboardTrade = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative flex min-h-screen w-full">
      <Topbar />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 bg-black opacity-50 z-20"
        ></div>
      )}
      <div
        className={`flex-grow dark:bg-pebble flex flex-col transition-all duration-300 ${isSidebarOpen ? "z-10" : ""}`}
      >
        <div className="flex-grow flex justify-center items-start">
          <div className="w-full max-w-[1400px] mx-auto mt-24 lg:mt-12">
            <h1 className="text-3xl font-normal mb-4 text-black dark:text-white text-left lg:pt-12 ml-4">
              Trade
            </h1>
            <Iframe
              url={import.meta.env.VITE_APPLICATION_URL}
              styles={{ width: "100%", height: "80vh" }}
              className=""
              display="block"
              allow="camera"
              target="_top"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTrade;
