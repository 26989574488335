import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { TextInput } from "@/components/widget-components";
import { useSignupStore } from "@/reducers";
import { setUserPassword } from "@/requests/signup";
import { submitOnEnter } from "@/utils/keyboard";

// 6-60 characters
// at least one letter
// at least one number
// at least one special character (!@#$%^&*)

const checkPasswordErrors = (password: string) => {
  const errors: string[] = [];
  if (password.length < 6 || password.length > 60) {
    errors.push("Password must be between 6 and 60 characters");
  }
  if (!/[a-zA-Z]/.test(password)) {
    errors.push("Password must contain at least one letter");
  }
  if (!/[0-9]/.test(password)) {
    errors.push("Password must contain at least one number");
  }
  if (!/[!@#$%^&*]/.test(password)) {
    errors.push(
      "Password must contain at least one special character (!@#$%^&*)"
    );
  }

  return errors;
};

export default function SignupContentPassword() {
  const navigate = useNavigate();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const nextState = useSignupStore.use.nextState();
  const canSubmit = !!password1.length && !!password2.length && !errors.length;
  const email = useSignupStore.use.email();
  const verificationCode = useSignupStore.use.verificationCode();

  const submitIfValid = (e: FormEvent) => {
    e.preventDefault();
    if (password1 !== password2) {
      setErrors(["Passwords entered must match"]);
      return;
    } else {
      setUserPassword(email, verificationCode, password1, password2);
      nextState();
      navigate("/Complete");
    }
  };

  useEffect(() => {
    if (!!password1.length || !!password2.length) {
      setErrors(checkPasswordErrors(password1));
    }
  }, [password1, password2]);

  return (
    <form onSubmit={submitIfValid}>
      <div className="flex flex-col gap-5">
        <div className="text-lg font-normal uppercase">Password</div>
        <TextInput
          autoFocus
          placeholder="Enter password"
          type="password"
          onChange={(e) => setPassword1(e.target.value)}
          value={password1}
        />
        <TextInput
          placeholder="Re-enter password"
          type="password"
          onChange={(e) => setPassword2(e.target.value)}
          onKeyDown={submitOnEnter(submitIfValid)}
          value={password2}
        />
        <ul>
          {errors.map((error, index) => (
            <li key={index}>
              <div className="text-rose-600">{error}</div>
            </li>
          ))}
        </ul>
        <Button type="submit" disabled={!canSubmit}>
          SUBMIT
        </Button>
      </div>
    </form>
  );
}
