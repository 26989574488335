import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  getOrderStatusByTransactionId,
  OrderStatusResponse,
} from "@/requests/order";
import InteracLogo from "@/assets/interac-circle.png";
import { WidgetLayout } from "@/pages/Buy/WidgetLayout";
import { DarkModeMenu } from "@/panels/signup/DarkmodeSignUpPanel";
import "./OrderStatus.css";
import { HybridWalletConnectButton } from "@/components/Button/HybridWalletConnect";
import { CopyIcon } from "@radix-ui/react-icons";
import {
  TRANSACTION_LISTENER_STORAGE_KEY,
  StoredTransaction,
} from "@/hooks/useTransactionListener";
import { WalletAddressQRCode } from "@/utils/WalletQrCode";

const OrderStatus = () => {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("id");
  const parent = searchParams.get("parent");
  const [showExchangeModal, setShowExchangeModal] = useState(false);

  const [orderDetails, setOrderDetails] = useState<OrderStatusResponse | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (transactionId) {
      fetchOrderDetails(transactionId);
    }
    const interval = setInterval(() => {
      if (transactionId) {
        // reset();
        fetchOrderDetails(transactionId);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [transactionId]);

  const fetchOrderDetails = async (transactionId: string) => {
    try {
      const response = await getOrderStatusByTransactionId(transactionId);
      setOrderDetails(response.data);
    } catch {
      setError("Failed to fetch order details");
    }
  };

  const getStatusLabel = (status: string, orderId?: string) => {
    const lowerStatus = status.toLowerCase();

    // Check if transaction is completed in localStorage
    if (orderId) {
      const storedTransactions = getStoredTransactions();
      const storedTransaction = storedTransactions[orderId];
      if (
        storedTransaction?.status === "completed" &&
        (lowerStatus.includes("pending") || lowerStatus.includes("waiting"))
      ) {
        return "Payment completed - Awaiting confirmation";
      }
    }

    if (lowerStatus.includes("pending")) {
      return "Pending e-transfer payment";
    } else if (lowerStatus.includes("complete")) {
      return "Complete";
    } else if (lowerStatus.includes("cancelled")) {
      return "Cancelled";
    } else if (lowerStatus.includes("expired")) {
      return "Expired";
    } else if (lowerStatus.includes("waiting")) {
      return ""; //@ dev we don't want to display this
    } else if (lowerStatus.includes("processing")) {
      return "Processing request money transfer";
    } else if (lowerStatus.includes("sending")) {
      return "Sending funds";
    } else {
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }
  };

  const truncateMiddle = (str: string, length: number) => {
    if (str.length <= length) {
      return str;
    }
    const frontLength = Math.ceil(length / 2);
    const backLength = Math.floor(length / 2);
    return `${str.substr(0, frontLength)}...${str.substr(str.length - backLength)}`;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date
      .toLocaleDateString("en-US", options)
      .replace(/, /g, " ")
      .replace(/(\d{4})/, "$1,");
  };

  const getConfirmationUrl = () => {
    if (!orderDetails) return "https://etherscan.io/";

    const { leftSideLabel, rightSideLabel } = orderDetails;

    if (
      ["USDT-BSC", "BUSD-BSC", "USDC-BSC", "USDT-B", "BUSD-B"].includes(
        leftSideLabel
      ) ||
      ["USDT-BSC", "BUSD-BSC", "USDC-BSC", "USDT-B", "BUSD-B"].includes(
        rightSideLabel
      )
    ) {
      return "https://bscscan.com/";
    } else if (
      ["USDC-POLY", "CADC-POLY", "USDC-P", "CADC-P"].includes(leftSideLabel) ||
      ["USDC-POLY", "CADC-POLY", "USDC-P", "CADC-P"].includes(rightSideLabel)
    ) {
      return "https://polygonscan.com/";
    } else if (
      ["USDC-ARB", "CADC-ARB"].includes(leftSideLabel) ||
      ["USDC-ARB", "CADC-ARB"].includes(rightSideLabel)
    ) {
      return "https://arbiscan.io/";
    } else if (
      ["USDC-ETH", "CADC-ETH"].includes(leftSideLabel) ||
      ["USDC-ETH", "CADC-ETH"].includes(rightSideLabel)
    ) {
      return "https://etherscan.io/";
    } else if (leftSideLabel === "USDC-OP" || rightSideLabel === "USDC-OP") {
      return "https://optimistic.etherscan.io/";
    } else if (
      leftSideLabel === "USDC-AVAX" ||
      rightSideLabel === "USDC-AVAX"
    ) {
      return "https://snowtrace.io/";
    } else if (leftSideLabel === "USDC-FTM" || rightSideLabel === "USDC-FTM") {
      return "https://ftmscan.com/";
    } else if (leftSideLabel === "USDC-GNO" || rightSideLabel === "USDC-GNO") {
      return "https://gnosisscan.io/";
    } else if (
      leftSideLabel === "USDC-CELO" ||
      rightSideLabel === "USDC-CELO"
    ) {
      return "https://celoscan.io/";
    } else if (leftSideLabel === "USDC-SX" || rightSideLabel === "USDC-SX") {
      return "https://explorerl2.sx.technology/";
    } else if (
      ["USDC-BASE", "CADC-BASE"].includes(leftSideLabel) ||
      ["USDC-BASE", "CADC-BASE"].includes(rightSideLabel)
    ) {
      return "https://basescan.org/";
    } else if (leftSideLabel === "USDC-SOL" || rightSideLabel === "USDC-SOL") {
      return "https://solscan.io/";
    } else {
      return "https://etherscan.io/";
    }
  };

  const handleTransferError = (error: Error) => {
    if (error?.message == "Sender USDC token account not found") {
      setError("Your Solana Wallet does not have a USDC token Account");
    } else if (
      error?.message ==
      "The requested method and/or account has not been authorized by the user."
    ) {
      setError(
        "You are attempting to sign with the wrong account. Disconnect and sign with the required wallet."
      );
    } else if (error?.message == "User rejected the request.") {
      setError("Failure to sign transaction.");
    } else {
      setError(
        "We’re unable to process your transaction. Please try again or contact support@paytrie.com if the issue persists."
      );
    }
  };

  const handleTransferComplete = (txHash: string) => {
    console.log("Transfer completed:", txHash);
    // Optionally refresh order details
    if (transactionId) {
      fetchOrderDetails(transactionId);
    }
  };

  const confirmationUrl = getConfirmationUrl();
  const isInIframe = window.self !== window.top;

  if (isInIframe) {
    try {
      window.parent.location.pathname.includes("/dashboard");
    } catch (error) {
      console.error(
        "Unable to access parent location. Ensure the iframe and parent page are on the same origin.",
        error
      );
    }
  }

  const getStoredTransactions = (): Record<string, StoredTransaction> => {
    try {
      const stored = localStorage.getItem(TRANSACTION_LISTENER_STORAGE_KEY);
      return stored ? JSON.parse(stored) : {};
    } catch (error) {
      console.error("Error reading stored transactions:", error);
      return {};
    }
  };

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text).then(
        () => {},
        () => {
          fallbackCopyToClipboard(text);
        }
      );
    } catch {
      fallbackCopyToClipboard(text);
    }
  };

  const fallbackCopyToClipboard = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    textarea.style.pointerEvents = "none";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
    } catch {
      alert("Failed to copy. Please copy manually.");
    }
    document.body.removeChild(textarea);
  };

  return (
    <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
      {window.self === window.top && parent !== "landing" && (
        <div className="absolute top-10 right-0">
          <DarkModeMenu showSignOutButton={true} />
        </div>
      )}
      <div className="order-status-container flex items-center justify-center">
        <div
          className="w-full max-w-4xl px-4 dark:text-white"
          style={{
            position: "absolute",
            top: window.self === window.top ? "10%" : "9%",
            width: "100%",
          }}
        >
          <h2 className="text-lg font-normal mb-6 text-center text-black dark:text-white mt-12">
            ORDER STATUS
          </h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}

          {orderDetails ? (
            <div className="px-4 sm:px-6">
              <h3 className="text-lg font-semibold mb-4 text-center text-black dark:text-white">
                {getStatusLabel(orderDetails.status, orderDetails.payment_id)}
              </h3>

              {orderDetails.status.toLowerCase().includes("pending") ||
              (orderDetails.status.toLowerCase().includes("waiting") &&
                orderDetails.leftSideLabel === "CAD") ? (
                <div
                  className="flex items-center justify-center mb-10 pt-10 pb-10"
                  style={{ height: "3rem" }}
                >
                  <div className="w-full flex justify-center">
                    <a
                      className="bg-black text-white dark:bg-semiWhite dark:text-black py-8 px-4 border-black w-full border-2 items-center text-center"
                      href={`https://etransfer.interac.ca/reqPayment/eml/${orderDetails.atb_status}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PAY WITH
                      <img
                        src={InteracLogo}
                        alt="Interac Logo"
                        className="inline-block ml-2"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
              {orderDetails.leftSideLabel != "CAD" &&
                (orderDetails.status.toLowerCase().includes("pending") ||
                  orderDetails.status.toLowerCase().includes("waiting")) && (
                  <div className="mb-8 sm:mb-10">
                    <div className="grid grid-cols-1 sm:grid-cols-[1fr_auto_1fr] gap-4 items-center">
                      {/* Wallet payment button */}
                      <div className="border-none border-gray-200 dark:border-gray-700 rounded-none">
                        <HybridWalletConnectButton
                          amount={parseFloat(orderDetails.leftSideValue)}
                          recipientAddress={
                            orderDetails.leftSideLabel.includes("SOL")
                              ? import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                              : import.meta.env.VITE_PAYTRIE_WALLET_ADDR
                          }
                          senderAddress={orderDetails.wallet}
                          tokenSymbol={orderDetails.leftSideLabel}
                          orderId={orderDetails.payment_id}
                          onTransferComplete={handleTransferComplete}
                          onError={handleTransferError}
                        />
                      </div>

                      {/* OR divider */}
                      <div className="text-center mt-2">
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400 p-0">
                          OR
                        </span>
                      </div>

                      {/* Exchange payment button */}

                      <button
                        className="cursor-pointer"
                        onClick={() => setShowExchangeModal(true)}
                      >
                        <a className="w-full py-6 bg-white  text-black dark:bg-black dark:text-white border-black text-center block">
                          <span> PAY WITH EXCHANGE</span>
                        </a>
                      </button>
                    </div>
                  </div>
                )}

              <div className="space-y-2 px-4 sm:px-6">
                <div className="flex justify-between text-black dark:text-white text-xs sm:text-sm lg:text-base">
                  <span className="min-w-[100px] font-bold">Date:</span>
                  <span className="text-right ml-4 flex-grow">
                    {formatDate(orderDetails.date)}
                  </span>
                </div>

                {orderDetails.status.toLowerCase().includes("complete") &&
                  orderDetails.payment_id && (
                    <div className="flex justify-between text-black dark:text-white text-xs sm:text-sm lg:text-base">
                      <span className="font-semibold min-w-[100px]">
                        Confirmation
                      </span>
                      <span className="text-right ml-4 flex-grow break-all">
                        <a
                          href={`${confirmationUrl}tx/${orderDetails.payment_id}`}
                          rel="nofollow noopener noreferrer"
                          target="_blank"
                          className="text-blue-500 break-all"
                        >
                          {truncateMiddle(orderDetails.payment_id, 20)}
                        </a>
                      </span>
                    </div>
                  )}

                <div className="flex justify-between text-black dark:text-white text-xs sm:text-sm lg:text-base">
                  <span className="font-semibold min-w-[100px]">
                    Tx&nbsp;ID:
                  </span>
                  <span className="text-right ml-4 flex-grow break-all">
                    {orderDetails.tx_id}
                  </span>
                </div>

                <div className="flex justify-between text-black dark:text-white text-xs sm:text-sm lg:text-base">
                  <span className="font-semibold min-w-[100px]">You Pay:</span>
                  <span className="text-right ml-4 flex-grow">
                    {parseFloat(orderDetails.leftSideValue).toFixed(2)}{" "}
                    {orderDetails.leftSideLabel}
                  </span>
                </div>

                <div className="flex justify-between text-black dark:text-white text-xs sm:text-sm lg:text-base">
                  <span className="font-semibold min-w-[100px]">
                    You Receive:
                  </span>
                  <span className="text-right ml-4 flex-grow">
                    {parseFloat(orderDetails.rightSideValue).toFixed(2)}{" "}
                    {orderDetails.rightSideLabel}
                  </span>
                </div>

                <div className="flex justify-between text-black dark:text-white text-xs sm:text-sm lg:text-base">
                  <span className="font-semibold min-w-[100px]">Wallet:</span>
                  <span className="text-right ml-4 flex-grow break-all">
                    <a
                      href={`${confirmationUrl}address/${orderDetails.wallet}#tokentxns`}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      className="text-blue-500 break-all"
                    >
                      {orderDetails.wallet}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-black dark:text-white">Loading...</p>
          )}
        </div>
      </div>

      {/* Exchange Modal with responsive text */}

      {showExchangeModal && (
        <div className="fixed inset-0 bg-pebble bg-opacity-20 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-pebble p-8 max-w-md w-full rounded-none border border-gray-200 dark:border-gray-700">
            <h3 className="text-xl font-semibold mb-6 text-black dark:text-white">
              PAY WITH EXCHANGE
            </h3>
            <div className="space-y-6 text-gray-600 dark:text-gray-300 mb-6">
              {/* Amount */}
              <div className="space-y-2">
                <p className="font-semibold text-black dark:text-white">
                  Amount:
                </p>
                <div className="flex items-center justify-between p-3 bg-gray-100 dark:bg-gray-700 h-14">
                  <span className="font-mono text-sm">
                    {orderDetails?.leftSideValue} {orderDetails?.leftSideLabel}
                  </span>
                  <button
                    onClick={() =>
                      copyToClipboard(orderDetails?.leftSideValue || "")
                    }
                    className="text-gray-600 hover:text-black dark:text-gray-400 dark:hover:text-white transition-colors flex-shrink-0"
                    title="Copy amount"
                  >
                    <CopyIcon />
                  </button>
                </div>
              </div>

              {/* Send to Wallet */}
              <div className="space-y-2">
                <p className="font-semibold text-black dark:text-white">
                  Send to Wallet:
                </p>
                <div className="flex items-center justify-between p-3 bg-gray-100 dark:bg-gray-700 h-14">
                  <a
                    href={`${confirmationUrl}address/${
                      orderDetails?.leftSideLabel?.includes("SOL")
                        ? import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                        : import.meta.env.VITE_PAYTRIE_WALLET_ADDR
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-mono text-sm break-all hover:text-black dark:hover:text-white transition-colors truncate"
                  >
                    {orderDetails?.leftSideLabel?.includes("SOL")
                      ? import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                      : import.meta.env.VITE_PAYTRIE_WALLET_ADDR}
                  </a>
                  <button
                    onClick={() =>
                      copyToClipboard(
                        orderDetails?.leftSideLabel?.includes("SOL")
                          ? import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                          : import.meta.env.VITE_PAYTRIE_WALLET_ADDR
                      )
                    }
                    className="text-gray-600 hover:text-black dark:text-gray-400 dark:hover:text-white transition-colors ml-2 flex-shrink-0"
                    title="Copy address"
                  >
                    <CopyIcon />
                  </button>
                </div>
              </div>

              {/* QR Code (using SVG directly in the component) */}
              <div className="flex justify-center py-2">
                <div className="bg-white p-2">
                  <WalletAddressQRCode
                    address={
                      orderDetails?.leftSideLabel?.includes("SOL")
                        ? import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                        : import.meta.env.VITE_PAYTRIE_WALLET_ADDR
                    }
                    size={200}
                  />
                </div>
              </div>

              {/* Important Notes */}
              <div className="space-y-2">
                <p className="font-semibold text-black dark:text-white">
                  Important:
                </p>
                <div className="space-y-2">
                  <p className="text-sm">
                    • Include{" "}
                    <span className="font-semibold">
                      exchange withdrawal fees
                    </span>{" "}
                    in your total
                  </p>
                  <p className="text-sm">
                    • Only send {orderDetails?.leftSideLabel?.split("-")[0]} on
                    the {orderDetails?.leftSideLabel?.split("-")[1]} network
                  </p>
                  <p className="text-sm">
                    • Please allow up to 24 hours to process the order
                  </p>
                </div>
              </div>
            </div>

            <button
              onClick={() => setShowExchangeModal(false)}
              className="w-full px-4 py-3 bg-black text-white dark:bg-white dark:text-black rounded-none hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors text-sm sm:text-base"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </WidgetLayout>
  );
};

export default OrderStatus;
