import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "./DashboardProfileStyles.css";
import OrderForm from "./OTC/OrderForm";

export const DashboardOTC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative flex min-h-screen w-full lg:ml-[10px]">
      <Topbar />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 bg-black opacity-50 z-20"
        ></div>
      )}
      <div className={`flex-grow flex flex-col ${isSidebarOpen ? "z-10" : ""}`}>
        <div className="flex flex-col items-center justify-center flex-grow pt-2 w-full">
          <div className="relative w-full lg:min-w-[1200px] lg:ml-[-230px] mx-auto">
            <div className="p-7 sm:p-8 md:px-12 md:pt-1 mt-2 md:mt-8 w-full">
              <h1 className="text-3xl font-normal text-left ml-10 mb-8 text-black dark:text-white lg:mt-12 md:ml-[40px] mt-10"></h1>
              <div className=" overflow-y-auto lg:overflow-hidden hide-scrollbar">
                <OrderForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardOTC;
