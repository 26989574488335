import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import {
  NoAccountText,
  PoweredByLabel,
  TextInput,
} from "@/components/widget-components";
import { usePageStateStore } from "@/reducers";
import { PanelLayout } from "./PanelLayout";

const KeyLoginPanel = () => {
  const setApiKey = usePageStateStore.use.setApiKey();
  const [paytrieKey, setKey] = useState<string>("");
  const navigate = useNavigate();

  const useApiKey = () => {
    // TODO: Determine actual key criteria
    if (paytrieKey.length > 10) {
      setApiKey(paytrieKey);
      navigate("/");
    }
  };

  return (
    <PanelLayout>
      <div className="flex flex-col gap-5">
        <h2 className="text-base font-normal uppercase">Key Login</h2>
        <div className="text-base">Enter your unique key ID</div>
        <TextInput
          placeholder="Enter key"
          type="text"
          onChange={(e) => setKey(e.target.value)}
          value={paytrieKey}
        />
        <Button disabled={paytrieKey.length < 10} onClick={useApiKey}>
          SEND
        </Button>
        <NoAccountText>
          don&apos;t have an account?{" "}
          <a
            className="cursor-pointer underline hover:text-clementine dark:hover:text-sun"
            onClick={() => navigate("/Signup")}
          >
            sign up
          </a>
          .
        </NoAccountText>
      </div>
      <PoweredByLabel />
    </PanelLayout>
  );
};

export default KeyLoginPanel;
