import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isAddress } from "web3-utils"; // TODO: use viem

import { Button } from "@/components/Button/Button";
import { Checkbox } from "@/components/Checkbox";
import { LoadingIcon } from "@/components/LoadingIcon";
import {
  BackButton,
  EllipsisAnimation,
  TextInput,
  NoAccountText,
} from "@/components/widget-components";
import { usePageStateStore } from "@/reducers";
import {
  TransactionType,
  useBuySellStore,
} from "@/reducers/transactionReducer";
import { submitBuy, submitSell } from "@/requests/transaction";
import { getLoggedInUser } from "@/requests/user";
import {
  Networks,
  NetworkOptions,
  NetworkValue,
  StableCoins,
  FEERATE,
  FEERATECADC,
  MINUSDCFEE,
  MINCADCFEE,
} from "@/utils/currencies";
import { SummaryRow } from "./SummaryRows";
import { UseEthInfo } from "./UseEthInfo";
import { SimpleWalletConnect } from "@/components/Button/AddWalletConnect";
import { SelectedWalletAccountContext } from "@/contexts/WalletAccountProvider";
import { address } from "@solana/kit";
import * as Sentry from "@sentry/react";

type AddWalletProps = {
  wallet: string;
  onSetWallet: (wallet: string) => void;
  onSubmitSuccess: (tx: string) => void;
  setLoading: (loading: boolean) => void;
};

// Function to capture API errors in Sentry
const captureApiError = (
  error: Error,
  contextData: Record<string, any> = {}
) => {
  // Extract error patterns for better classification
  const errorMessage = error?.message || "";
  const isNetworkError =
    errorMessage.includes("network") || errorMessage.includes("connect");
  const isAuthError =
    errorMessage.includes("verify") ||
    errorMessage.includes("auth") ||
    errorMessage.includes("token");
  const isLimitError =
    errorMessage.includes("limit") || errorMessage.includes("exceed");
  const isInteracError =
    errorMessage.includes("interact") || errorMessage.includes("SF_003");

  // Create error classification for better grouping in Sentry
  const errorType = isNetworkError
    ? "api.network_error"
    : isAuthError
      ? "api.auth_error"
      : isLimitError
        ? "api.limit_error"
        : isInteracError
          ? "api.interac_error"
          : "api.other_error";

  // Capture with enriched context
  Sentry.captureException(error, {
    tags: {
      error_type: errorType,
      transaction_type: contextData.transactionType || "unknown",
      network: contextData.network || "unknown",
      api_action: contextData.apiAction || "unknown",
    },
    extra: {
      // Add minimal context data
      stableCoin: contextData.stableCoin,
      errorDetails: contextData.errorDetails,
    },
    level: "error",
  });

  // Also log to console for local debugging
  console.error(`API Error (${errorType}):`, error, contextData);
};

export const AddWallet = ({
  wallet,
  onSetWallet,
  onSubmitSuccess,
  setLoading,
}: AddWalletProps) => {
  const [loading, setIsLoading] = useState(false);
  const state = useBuySellStore.getState();
  const email = usePageStateStore.use.email();
  const [error, setError] = useState("");
  const [useEth, setUseEth] = useState<boolean>(false); // DEV: useEth is always false
  const [showUseEthInfo, setShowUseEthInfo] = useState<boolean>(false);
  const [quoteEngine, network] = [
    useBuySellStore.use.quoteEngine(),
    useBuySellStore.use.network(),
  ];
  const navigate = useNavigate();
  const gasFees = quoteEngine?.getGasFee(network) || 0;
  const ethAmount = gasFees * 2;
  const [referralCredit, setReferralCredit] = useState(0);
  const authToken = usePageStateStore.use.authToken() || "";
  const [useReferral, setUseReferral] = useState(false);
  const [fiatAmount, stableCoinAmount] = [
    useBuySellStore.use.fiatAmount(),
    useBuySellStore.use.stableCoinAmount(),
  ];
  const [setReferralAdjustedFiatAmount, setReferralAdjustedCoinAmount] = [
    useBuySellStore.use.setReferralAdjustedFiatAmount(),
    useBuySellStore.use.setReferralAdjustedCoinAmount(),
  ];

  const [selectedWalletAccount] = useContext(SelectedWalletAccountContext);

  useEffect(() => {
    if (network === Networks.sol && selectedWalletAccount) {
      onSetWallet(selectedWalletAccount.address);
    }
  }, [network, selectedWalletAccount, onSetWallet]);

  // use effect to fetch user details
  useEffect(() => {
    async function fetchUser() {
      setIsLoading(true);

      try {
        const { response, error } = await getLoggedInUser(authToken);

        if (error) {
          if (axios.isAxiosError(error)) {
            console.log("ERROR:", error);
            setError("Could not fetch user.");

            // Log specific axios error details
            Sentry.addBreadcrumb({
              category: "api.error",
              message: "Failed to fetch user",
              level: "error",
              data: {
                status: error.response?.status,
                statusText: error.response?.statusText,
                errorMessage: error.message,
              },
            });
          } else {
            setError("An unknown error occurred. Unable to create transaction");
            captureApiError(error as Error, {
              apiAction: "getLoggedInUser",
              network,
            });
          }
          setIsLoading(false);
          navigate("/EasyLogin");
        } else if (response) {
          //onSetWallet(response.data.wallet); // CU-86dveqy2j - dont autopopulate last used wallet
          setReferralCredit(response.data.referral_credits);
          setIsLoading(false);
        }
      } catch (unexpectedError) {
        setError("An unknown error occurred. Unable to fetch user details");
        setIsLoading(false);

        captureApiError(unexpectedError as Error, {
          apiAction: "getLoggedInUser",
          network,
          errorDetails: "Unexpected error during user fetch",
        });
      }
    }

    fetchUser();
  }, []);

  // Methods stub until logic is explained to me
  const applyReferralCredit = () => {
    if (!useReferral) {
      //const cadusd = 1.343;
      console.log("TRANSACTIONTYPE", state.transactionType);
      const cadusd =
        state.stableCoin === StableCoins.USDC
          ? state.quoteEngine?.getCadUsdConversion() || 1.3484
          : 1;

      if (state.transactionType === TransactionType.BUY) {
        console.log("BUY OPERATION");
        //const updatedAmount = stableCoinAmount + paytrieFeesAmt;
        console.log(`BUY ${state.stableCoin}`);

        if (state.network === Networks.ethereum) {
          console.log(`BUY ${state.stableCoin}_ETH`);
          const fee = (fiatAmount - gasFees) * FEERATE;
          console.log("FEE:", fee);
          if (fee > 15) {
            const updatedAmount = (fiatAmount - gasFees - fee + 15) / cadusd;
            console.log("FEE > 15");
            console.log("RSV:", updatedAmount);
            setReferralAdjustedCoinAmount(updatedAmount);
          } else {
            const updatedAmount = (fiatAmount - gasFees) / cadusd;
            console.log("FEE <= 15");
            console.log("RSV:", updatedAmount);
            setReferralAdjustedCoinAmount(updatedAmount);
          }
        } else {
          console.log(`BUY ${state.stableCoin} (NONETH)`);
          const fee = fiatAmount * FEERATE;
          console.log("FEE:", fee);
          if (fee > 15) {
            const updatedAmount = (fiatAmount - fee + 15) / cadusd;
            console.log("FEE > 15");
            console.log("RSV:", updatedAmount);
            setReferralAdjustedCoinAmount(updatedAmount);
          } else {
            const updatedAmount = fiatAmount / cadusd;
            console.log("FEE <= 15");
            console.log("RSV:", updatedAmount);
            setReferralAdjustedCoinAmount(updatedAmount);
          }
        }

        //console.log("UPDATED AMOUNT:", updatedAmount);
        //setReferralAdjustedCoinAmount(updatedAmount);
      } else if (state.transactionType === TransactionType.SELL) {
        let fee = 0;
        if (state.stableCoin === StableCoins.USDC) {
          console.log("FEE USDC");
          fee = stableCoinAmount * FEERATE;
          console.log("FEE:", fee);
          if (fee / cadusd <= MINUSDCFEE) {
            console.log("FEE OVER MINUSDFEE");
            fee = MINUSDCFEE * cadusd;
            console.log("FEE:", fee);
          }
        } else if (state.stableCoin === StableCoins.CADC) {
          fee = stableCoinAmount * FEERATECADC;
          console.log("FEE:", fee);
          if (fee < MINCADCFEE) {
            console.log("FEE OVER MINCADCFEE");
            fee = MINCADCFEE;
            console.log("FEE:", fee);
          }
        }

        if (fiatAmount >= 9985) {
          console.log("FIAT AMT >= 9985");
          const updatedAmount = (fiatAmount - 15) / cadusd / (1 - FEERATE);
          console.log("UPDATED AMT:", updatedAmount);
          setReferralAdjustedCoinAmount(updatedAmount);
        } else if (stableCoinAmount >= 2500) {
          if (state.stableCoin === StableCoins.USDC) {
            console.log("STABLECOIN AMT >= 2500");
            const updatedAmount = fiatAmount + 15;
            console.log("UPDATED AMT:", updatedAmount);
            setReferralAdjustedFiatAmount(updatedAmount);
          } else {
            const updatedAmount = fiatAmount + (stableCoinAmount - fiatAmount);
            console.log("UPDATED AMT:", updatedAmount);
            setReferralAdjustedFiatAmount(updatedAmount);
          }
        } else {
          console.log("STABLECOIN AMT < 2500");
          if (fee >= 15) {
            console.log("FEE >= 15");
            const updatedAmount = fiatAmount + 15;
            console.log("UPDATED AMT:", updatedAmount);
            setReferralAdjustedFiatAmount(updatedAmount);
          } else {
            console.log("FEE < 15");
            const updatedAmount = fiatAmount + fee;
            console.log("UPDATED AMT:", updatedAmount);
            setReferralAdjustedFiatAmount(updatedAmount);
          }
        }

        //const updatedAmount = fiatAmount + paytrieFeesAmt;
        //setReferralAdjustedFiatAmount(updatedAmount);
      }

      setUseReferral(true);
    } else {
      setUseReferral(false);
    }
  };

  const validateAddress = () => {
    // if LSL = USD-SOL || RSL = USD-SOL
    // return true
    // else
    // return isAddress
    if (network === Networks.sol) {
      if (wallet) {
        try {
          address(wallet);
          return true;
        } catch {
          // If empty or invalid address is provided to PublicKey, it throws exception
          return false;
        }
      } else {
        return false;
      }
    } else {
      return isAddress(wallet);
    }
  };

  const setSellAmount = (useReferral: boolean) => {
    if (useReferral) {
      if (fiatAmount >= 9985) {
        return state.referralAdjustedCoinAmount;
      } else {
        return state.stableCoinAmount;
      }
    } else {
      return state.stableCoinAmount;
    }
  };

  const submit = async () => {
    setIsLoading(true);
    setLoading(true);

    Sentry.addBreadcrumb({
      message: `Starting Submit`,
      level: "error",
      data: {
        type: state.transactionType,
        gasId: state.quoteEngine?.gasId,
        quoteId: state.quoteEngine?.quoteId,
        stableCoin: state?.stableCoin,
        email,
        wallet,
        network: state.network,
        useReferral,
        token: authToken,
      },
    });

    const { error, response } =
      state.transactionType === TransactionType.SELL
        ? await submitSell({
            gasId: state.quoteEngine?.gasId,
            quoteId: state.quoteEngine?.quoteId as number,
            stableCoin: state.stableCoin,
            //amount: (useReferral) ? state.referralAdjustedCoinAmount : state.stableCoinAmount,
            amount: setSellAmount(useReferral),
            email,
            wallet,
            network: state.network,
            useReferral,
            token: authToken,
          })
        : await submitBuy({
            quoteId: state.quoteEngine?.quoteId ?? 0,
            email,
            wallet,
            gasId: state.quoteEngine?.gasId ?? 0,
            //amount: (useReferral) ? state.referralAdjustedFiatAmount : state.fiatAmount,
            amount: state.fiatAmount,
            stableCoin: state.stableCoin,
            network: state.network,
            ...(useEth && { ethCost: ethAmount }),
            useReferral,
            token: authToken,
          });

    if (error) {
      if (axios.isAxiosError(error)) {
        if (
          error?.response?.data?.message ===
          "Too many active transactions, please complete or cancel existing transactions before continuing"
        ) {
          setError(
            "You have too many active transactions. Please complete or cancel your existing transactions before proceeding."
          );
        } else if (
          error?.response?.data?.message ===
          "Too many cancelled/expired transactions today, please try again tomorrow"
        ) {
          setError(
            `You've had too many cancelled or expired transactions today. Please try again tomorrow or contact support@paytrie.com if the issue persists.`
          );
        } else if (
          error?.response?.data?.message?.startsWith(
            "You have exceed your daily limit by"
          )
        ) {
          setError(
            `You've reached your daily transaction limit. Please try again tomorrow.`
          );
        } else if (
          error?.response?.data?.message === "You have exceed your daily limit"
        ) {
          setError(
            `You've reached your daily transaction limit. Please try again tomorrow.`
          );
        } else if (
          error?.response?.data?.message ===
          "Unable to process transaction. You have not been verified by PayTrie. Please allow up to 24 hours for verification"
        ) {
          setError(
            `We’re unable to process your transaction. Your account is not verified. Please sign up at register.paytrie.com`
          );
        } else if (
          error?.response?.data?.message ===
          "Unable to process transaction. You have not verified your email address. Please check your inbox and spam folder for our verification email."
        ) {
          setError(
            `We’re unable to process your transaction. Your account is not verified. Please sign up at app.paytrie.com/signup.`
          );
        } else if (
          error?.response?.data?.message ===
          "Unable to process transaction. You have not verified your phone number."
        ) {
          setError(
            `We’re unable to process your transaction. Your account is not verified. Please sign up at register.paytrie.com`
          );
        } else if (
          error?.response?.data?.message === "ERROR CONNECTING WITH INTERACT"
        ) {
          setError(
            `Interac encountered an error. Please try again or contact support@paytrie.com if the issue persists.`
          );
        } else if (
          error?.response?.data?.message ===
          "Unable to send rmt.  Error code SF_003.  Please try again later or contact us at support@paytrie.com"
        ) {
          setError(
            `Interac encountered an error. Please try again or contact support@paytrie.com if the issue persists.`
          );
        } else if (
          error?.response?.data?.message ===
          "Unable to process request at the moment.  Please try again later or contact us at support@paytrie.com"
        ) {
          setError(
            `Interac encountered an error. Please try again or contact support@paytrie.com if the issue persists.`
          );
        } else {
          setError(
            "We’re unable to process your transaction. Please try again or contact support@paytrie.com if the issue persists."
          );
        }
      } else {
        setError("An unknown error occurred. Unable to create transaction");

        // Capture unknowsn error in Sentry
        captureApiError(error as Error, {
          apiAction:
            state.transactionType === TransactionType.SELL
              ? "submitSell"
              : "submitBuy",
          transactionType: state.transactionType,
          network: state.network,
          stableCoin: state.stableCoin,
        });
      }
      setIsLoading(false);
    } else if (response) {
      setIsLoading(false);
      onSubmitSuccess(response.data.tx);
    }
  };

  if (loading) {
    return (
      <div className="my-auto flex flex-col justify-center items-center">
        <LoadingIcon />
        <EllipsisAnimation>Processing transaction</EllipsisAnimation>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      <BackButton onClick={() => navigate("/")} className="absolute top-12">
        &#x27f5;
      </BackButton>
      <h1 className="text-lg tracking-tight uppercase">
        Enter your {NetworkOptions[network as NetworkValue].name} address
      </h1>

      <div className="flex gap-2 items-start">
        <div className="flex-1">
          <TextInput
            placeholder="Wallet address"
            value={wallet}
            maxLength={50}
            onChange={(e) => onSetWallet(e.target.value)}
          />
        </div>
        <SimpleWalletConnect
          network={network}
          onWalletConnect={onSetWallet}
          isValidManualAddress={validateAddress()}
          currentWallet={wallet}
        />
      </div>

      <BuyOrderSummary useReferral={useReferral} />
      {referralCredit > 0 && (
        <NoAccountText>
          <Checkbox
            label={`Use referral credit`}
            checked={useReferral}
            onCheckedChange={applyReferralCredit}
          />
        </NoAccountText>
      )}
      <div className="my-2 text-rose-600">{error}</div>
      <Button
        disabled={loading || !validateAddress()}
        onClick={submit}
        className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
      >
        {!validateAddress() ? "Invalid Wallet Address" : "Submit"}
        <span className="ml-5 mb-2">&#x27f6;</span>
      </Button>
      {showUseEthInfo && (
        <UseEthInfo
          onHide={() => {
            setShowUseEthInfo(false);
          }}
        />
      )}
    </div>
  );
};

type SummaryProps = {
  useReferral: boolean;
};

const BuyOrderSummary = ({ useReferral }: SummaryProps) => {
  return (
    <div>
      <h2 className="text-lg font-normal tracking-tight mb-2 mt-6">
        ORDER SUMMARY
      </h2>
      <SummaryRow useReferral={useReferral} />
    </div>
  );
};
