import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import {
  PaytrieTopIcon,
  PoweredByLabel,
  LoginHeader,
} from "@/components/widget-components";
import { Pages, usePageStateStore } from "@/reducers";
import { Stepper } from "@/pages/Signup/Stepper/Stepper";

export const RegistrationFailure = () => {
  const goToPage = usePageStateStore.use.goToPage();
  const navigate = useNavigate();

  const returnToHome = () => {
    navigate("/EasyLogin");
  };

  return (
    <div className="flex flex-col md:flex-row">
      <LoginHeader>
        <Stepper />
      </LoginHeader>
      <div className="relative p-5 lg:py-20 w-full">
        <PaytrieTopIcon className="absolute top-5 right-5 hidden md:block" />
        <div className="flex-grow mb-5">
          <div className="flex flex-col gap-5">
            <div className="text-2xl font-normal uppercase">
              Thank you for signing up with Paytrie!
            </div>
            <br />
            Unfortunately, we were unable to verify your identity. If you
            believe this is an error, please contact us at support@paytrie.com.
            <br />
            <br />
            <br />
            <Button onClick={returnToHome}>RETURN TO HOMEPAGE</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
