import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PanelLayout } from "./PanelLayout";
import { verifyEmail } from "@/requests/login";
import { usePageStateStore } from "@/reducers";
import * as Sentry from "@sentry/react";

export default function LoginVerifyPanel() {
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email") || "";
  const id = queryParameters.get("id") || "";
  const redirectPath = queryParameters.get("redirectpath") || "";
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [countdown, setCountdown] = useState(10);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const setAuthToken = usePageStateStore.use.setAuthToken();
  const setLoggedInUser = usePageStateStore.use.setEmail();
  const setProtectedRouteRedirectPath =
    usePageStateStore.use.setProtectedRouteRedirectPath();

  useEffect(() => {
    async function callVerifyEmail(email: string, id: string) {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Verifying email link",
        level: "info",
      });

      try {
        const { response, errorMessage } = await verifyEmail(email, id);

        // Add single breadcrumb before API call
        Sentry.addBreadcrumb({
          category: "auth",
          message: "Verifying email link with data",
          level: "info",
          data: {
            email: email,
            id: id,
          },
        });

        if (response && response.data.status === "success") {
          setTitle("You are now signed in");
          setStatus(
            "Please return to the previous page to continue or redirecting to app in 10"
          );
          setSuccess(true);
          const authToken = response.data.token;
          if (authToken) {
            setAuthToken(authToken);
            setLoggedInUser(email);
            const intervalId = setInterval(() => {
              setCountdown((prev) => {
                if (prev === 1) {
                  clearInterval(intervalId);
                  navigate("/");
                }
                return prev - 1;
              });
            }, 1000);
            return () => clearInterval(intervalId);
          }
        } else if (
          errorMessage &&
          (errorMessage === "This link has expired" ||
            errorMessage === "Link expired. Link is only valid for 5 minutes" ||
            errorMessage === "Already verified")
        ) {
          // Capture expected error state
          Sentry.captureMessage("Email verification link expired", {
            level: "warning",
            tags: {
              component: "LoginVerifyPanel",
              errorType: "link_expired",
            },
          });

          setTitle("SIGN IN LINK EXPIRED");
          setStatus(
            "This sign-in link has expired. Please request a new one to continue."
          );
        } else {
          // Capture unexpected error
          Sentry.captureException(
            new Error(errorMessage || "Unknown verification error"),
            {
              tags: {
                component: "LoginVerifyPanel",
                action: "verifyEmail",
              },
            }
          );

          setTitle("");
          setStatus(
            "Something went wrong during sign-in. Please try again or contact support@paytrie.com if the issue persists."
          );
        }
      } catch (error) {
        // Capture any unexpected exceptions
        Sentry.captureException(error, {
          tags: {
            component: "LoginVerifyPanel",
            action: "verifyEmail",
          },
        });

        setTitle("");
        setStatus(
          "Something went wrong during sign-in. Please try again or contact support@paytrie.com if the issue persists."
        );
      }
    }

    // Call function to verify email
    callVerifyEmail(email, id);
  }, [email, id, setAuthToken, setLoggedInUser, navigate]);

  return (
    <PanelLayout>
      <div className="flex flex-col gap-5">
        <h2 className="text-base font-normal uppercase">{title}</h2>
        <div className="text-base">
          {success ? status.replace("10", countdown.toString()) : status}
        </div>
        {success && (
          <div className="text-base mt-2">
            If not redirected, please click{" "}
            <div
              className="underline text-blue-600"
              style={{ cursor: "pointer", display: "inline" }}
              onClick={() => {
                if (redirectPath.length > 0) {
                  setProtectedRouteRedirectPath("");
                  window.location.replace(
                    `${import.meta.env.VITE_APPLICATION_URL}/${redirectPath}`
                  );
                } else {
                  window.location.replace(import.meta.env.VITE_APPLICATION_URL);
                }
              }}
            >
              here
            </div>
            .
          </div>
        )}
      </div>
    </PanelLayout>
  );
}
