import { useState, useEffect, Fragment } from "react";
import {
  getBankDetailsById,
  getBankDetailsByUser,
  addBankDetails,
  deleteBankDetails,
} from "@/requests/order";
import * as RadixSelect from "@radix-ui/react-select";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { LoadingIcon } from "@/components/LoadingIcon";

const BankAccountForm = () => {
  const initialFormData = {
    accountHolderName: "",
    institutionNumber: "",
    transitNumber: "",
    accountNumber: "",
    currency: "",
  };
  const initialFormErrors = {
    accountHolderName: "",
    institutionNumber: "",
    transitNumber: "",
    accountNumber: "",
    currency: "",
  };
  const [addingAccount, setAddingAccount] = useState(false);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState<string | null>(null);
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const response = await getBankDetailsByUser();
        const bankAccounts = response.data;

        const detailedAccounts = await Promise.all(
          bankAccounts.map(async (account: any) => {
            const detailsResponse = await getBankDetailsById(account.bank_id);
            return {
              ...detailsResponse.data,
              bank_id: String(account.bank_id),
              institution_number: String(
                detailsResponse.data.institution_number
              ),
              transit_number: String(detailsResponse.data.transit_number),
              account_number: String(detailsResponse.data.account_number),
            };
          })
        );

        setAccounts(detailedAccounts);
      } catch (err) {
        setError("Failed to fetch bank accounts.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBankAccounts();
  }, []);

  const resetForm = () => {
    setFormData(initialFormData);
    setFormErrors(initialFormErrors);
  };

  const handleAddAccountClick = () => {
    resetForm();
    setAddingAccount(true);
  };

  const handleCancel = () => {
    resetForm();
    setAddingAccount(false);
  };

  const validateField = (name: string, value: string) => {
    let errorMsg = "";
    if (name === "accountHolderName") {
      if (value.trim() === "") {
        errorMsg = "Account holder name is required.";
      } else if (value.length > 50) {
        errorMsg = "Account holder name must be 50 characters or less.";
      } else if (!/^[A-Za-z0-9]+$/.test(value)) {
        errorMsg = "Account holder name must only contain letters and numbers.";
      }
    }
    if (name === "institutionNumber" && !/^\d{3}$/.test(value)) {
      errorMsg = "Institution number must be 3 digits.";
    }
    if (name === "transitNumber" && !/^\d{5}$/.test(value)) {
      errorMsg = "Transit number must be 5 digits.";
    }
    if (name === "accountNumber" && !/^\d{7,20}$/.test(value)) {
      errorMsg = "Account number must be between 7 and 20 digits.";
    }
    if (name === "currency" && value === "") {
      errorMsg = "Currency is required.";
    }
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: errorMsg }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === "accountHolderName") {
      value = value.replace(/[^A-Za-z0-9]/g, "");
    }
    if (
      ["institutionNumber", "transitNumber", "accountNumber"].includes(name)
    ) {
      value = value.replace(/\D/g, "");
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const isFormValid = () => {
    return (
      Object.values(formErrors).every((error) => error === "") &&
      Object.values(formData).every((value) => value !== "")
    );
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setError("");

    if (!isFormValid()) {
      setSubmitting(false);
      return;
    }

    try {
      const payload = {
        ...formData,
        institutionalNumber: String(formData.institutionNumber),
        transitNumber: String(formData.transitNumber),
        accountNumber: String(formData.accountNumber),
      };

      await addBankDetails(payload);
      setAddingAccount(false);
      setLoading(true);
      const response = await getBankDetailsByUser();
      const bankAccounts = response.data;
      const detailedAccounts = await Promise.all(
        bankAccounts.map(async (account: any) => {
          const detailsResponse = await getBankDetailsById(account.bank_id);
          return {
            ...detailsResponse.data,
            bank_id: String(account.bank_id),
            institution_number: String(detailsResponse.data.institution_number),
            transit_number: String(detailsResponse.data.transit_number),
            account_number: String(detailsResponse.data.account_number),
          };
        })
      );
      setAccounts(detailedAccounts);
    } catch (err) {
      setError("Failed to add bank account.");
      console.error(err);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const toggleRow = (index: number) => {
    setExpandedIndex((prev) => (prev === index ? null : index));
  };

  const openDeleteDialog = (bankId: string) => {
    setSelectedBankId(bankId);
    setIsDeleteConfirmVisible(true);
  };

  const deleteAccount = async (bankId: string) => {
    try {
      await deleteBankDetails(bankId);
      setAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account.bank_id !== bankId)
      );
      setExpandedIndex(null);
      setIsDeleteConfirmVisible(false);
      setSelectedBankId(null);
    } catch (err) {
      setError("Failed to delete bank account.");
      console.error(err);
    }
  };

  return (
    <div className="w-full mx-auto md:mt-[-30px] md:ml-[-22px] mt-4">
      {!addingAccount ? (
        <>
          {loading ? (
            <div className="mt-4">
              <LoadingIcon />
            </div>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : accounts.length > 0 ? (
            <>
              <div className="hidden md:block">
                <table className="w-full text-left border-collapse">
                  <thead>
                    <tr className="border-b">
                      <th className="text-2xl py-3">Label</th>
                      <th className="text-2xl py-3">Account</th>
                      <th className="text-2xl py-3">Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.map((account: any, index: number) => (
                      <Fragment key={account.bank_id}>
                        <tr
                          className="cursor-pointer border-b"
                          onClick={() => toggleRow(index)}
                        >
                          <td className="py-3">
                            {account.account_holder_name}
                          </td>
                          <td className="py-3">{`${account.institution_number}-${account.transit_number}-${account.account_number}`}</td>
                          <td className="py-3">{account.currency}</td>
                        </tr>
                        {expandedIndex === index && (
                          <tr className="bg-cloudDarker dark:bg-darkCharcoal">
                            <td colSpan={3} className="p-4">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  openDeleteDialog(account.bank_id);
                                }}
                                className="bg-white text-sm dark:bg-white text-black dark:text-black px-4 py-2"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="block md:hidden space-y-4">
                {accounts.map((account: any, index: number) => (
                  <div
                    key={account.bank_id}
                    className="border p-4 rounded"
                    onClick={() => toggleRow(index)}
                  >
                    <div className="mb-2">
                      <span className="font-semibold">Label:</span>{" "}
                      {account.account_holder_name}
                    </div>
                    <div className="mb-2">
                      <span className="font-semibold">Account:</span>{" "}
                      {`${account.institution_number}-${account.transit_number}-${account.account_number}`}
                    </div>
                    <div className="mb-2">
                      <span className="font-semibold">Currency:</span>{" "}
                      {account.currency}
                    </div>
                    {expandedIndex === index && (
                      <div className="mt-2">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openDeleteDialog(account.bank_id);
                          }}
                          className="bg-black dark:bg-white text-white dark:text-black px-4 py-2"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p className="text-lg">No bank accounts found.</p>
          )}
          <div className="mt-8">
            <button
              className="bg-black text-white px-6 py-3 text-lg font-medium dark:bg-white dark:text-black"
              onClick={handleAddAccountClick}
            >
              Add bank account
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="text-3xl font-normal mb-6">Account Details</h2>
          <div className="space-y-6">
            {[
              { label: "Label:", name: "accountHolderName", maxLength: 50 },
              { label: "Institution Number:", name: "institutionNumber" },
              { label: "Transit Number:", name: "transitNumber" },
              { label: "Account Number:", name: "accountNumber" },
            ].map(({ label, name, maxLength }) => (
              <div
                key={name}
                className="flex flex-col md:flex-row md:items-center md:space-x-6"
              >
                <label className="text-lg font-bold md:w-48">{label}</label>
                <div className="flex flex-col flex-1">
                  <input
                    type="text"
                    name={name}
                    autoComplete="off"
                    value={formData[name as keyof typeof formData]}
                    onChange={handleChange}
                    maxLength={
                      maxLength ||
                      (name === "institutionNumber"
                        ? 3
                        : name === "transitNumber"
                          ? 5
                          : name === "accountNumber"
                            ? 20
                            : undefined)
                    }
                    className="bg-transparent border-b-2 outline-none border-black dark:border-white py-2 w-full max-w-[250px]"
                  />
                  {formErrors[name as keyof typeof formErrors] && (
                    <p className="text-red-500 text-sm">
                      {formErrors[name as keyof typeof formErrors]}
                    </p>
                  )}
                </div>
              </div>
            ))}
            <div className="flex flex-col md:flex-row md:items-center md:space-x-6">
              <label className="text-lg font-medium md:w-48">Currency:</label>
              <div className="flex-1 max-w-[250px]">
                <RadixSelect.Root
                  value={formData.currency || undefined}
                  onValueChange={(value) =>
                    setFormData((prev) => ({ ...prev, currency: value }))
                  }
                >
                  <RadixSelect.Trigger className="py-2 h-full w-full inline-flex items-end leading-none gap-[5px] justify-between outline-none border-b-2 border-black dark:border-white dark:focus:outline-none dark:focus:ring-0">
                    <RadixSelect.Value
                      placeholder={
                        <span className="text-gray-500">Select currency</span>
                      }
                    />
                    <RadixSelect.Icon className="text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.Icon>
                  </RadixSelect.Trigger>
                  <RadixSelect.Portal>
                    <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                      <RadixSelect.ScrollUpButton className="p-2.5">
                        <ChevronUpIcon />
                      </RadixSelect.ScrollUpButton>
                      <RadixSelect.Viewport>
                        <RadixSelect.Item
                          value="CAD"
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText>CAD</RadixSelect.ItemText>
                        </RadixSelect.Item>
                        <RadixSelect.Item
                          value="USD"
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText>USD</RadixSelect.ItemText>
                        </RadixSelect.Item>
                      </RadixSelect.Viewport>
                      <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                        <ChevronDownIcon />
                      </RadixSelect.ScrollDownButton>
                    </RadixSelect.Content>
                  </RadixSelect.Portal>
                </RadixSelect.Root>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col md:flex-row md:space-x-6 justify-start">
            <button
              type="button"
              className={`px-16 py-4 text-base font-medium ${
                isFormValid()
                  ? "bg-black text-white dark:bg-white dark:text-black"
                  : "bg-gray-400 text-gray-700 cursor-not-allowed"
              }`}
              onClick={handleSubmit}
              disabled={!isFormValid() || submitting}
            >
              {submitting ? "Submitting..." : "Submit"}
            </button>
            <button
              type="button"
              className="px-16 py-4 text-base font-medium bg-white text-black dark:bg-black dark:text-white"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {isDeleteConfirmVisible && selectedBankId && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="absolute inset-0 bg-black opacity-70" />
          <div
            className="relative bg-white dark:bg-pebble p-6 shadow-lg text-center z-50 flex flex-col w-11/12 max-w-md mx-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-lg font-normal mb-4 mt-8">
              Are you sure you want to delete this bank account?
            </h2>
            <div className="flex justify-center space-x-6 mb-5">
              <button
                type="button"
                className="bg-black dark:bg-white text-white dark:text-black py-2 px-6"
                onClick={() => deleteAccount(selectedBankId)}
              >
                Yes
              </button>
              <button
                type="button"
                className="bg-white dark:bg-black text-black dark:text-white py-2 px-6 border border-black dark:border-white"
                onClick={() => {
                  setIsDeleteConfirmVisible(false);
                  setSelectedBankId(null);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankAccountForm;
