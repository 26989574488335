import React, { useState, useEffect, useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import { getPriceQuote, addOtcBuyOrder } from "@/requests/order";
import {
  InfoCircledIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as RadixSelect from "@radix-ui/react-select";
import * as Sentry from "@sentry/react";
import { isAddress } from "web3-utils";

import EthereumLogo from "../../../assets/networks/ethereum-eth-logo.png";
import PolygonLogo from "../../../assets/networks/polygon-matic-logo.png";
import ArbitrumLogo from "../../../assets/networks/arbitrum-arb-logo.png";
import BinanceLogo from "../../../assets/networks/bnb-bnb-logo.png";
import OptimismLogo from "../../../assets/networks/optimism-ethereum-op-logo.png";
import BaseLogo from "../../../assets/networks/Base_Symbol_Blue.png";
import SXLogo from "../../../assets/networks/sx-network-logo.png";
import SOLLogo from "../../../assets/img/icons/cryptoicons/SVG/sol.svg";
import AVAXLogo from "../../../assets/img/icons/cryptoicons/SVG/avax.svg";
import CELOLogo from "../../../assets/celo_network.svg";
import CAD from "../../../assets/currency/cad.png";
import CADC from "../../../assets/currency/CADC2.svg";
import USDC from "../../../assets/img/icons/cryptoicons/SVG/usdc.svg";
import USD from "../../../assets/currency/USD.png";

const currencyLogos: Record<string, string> = {
  CAD: CAD,
  USD: USD,
};

const stablecoinLogos: Record<string, string> = {
  USDC: USDC,
  CADC: CADC,
};

const networkLogos: Record<string, string> = {
  Ethereum: EthereumLogo,
  Polygon: PolygonLogo,
  Arbitrum: ArbitrumLogo,
  BSC: BinanceLogo,
  Optimism: OptimismLogo,
  Base: BaseLogo,
  SX: SXLogo,
  Solana: SOLLogo,
  Avalanche: AVAXLogo,
  Celo: CELOLogo,
};

interface BuyFormProps {
  setCurrency: (currency: string) => void;
  setShowOrderSummary: (show: boolean, data?: any) => void;
  setFormData: (data: any) => void;
}

interface TooltipPortalProps {
  targetRef: React.RefObject<HTMLElement>;
  visible: boolean;
  offsetX?: number;
  offsetY?: number;
  children: React.ReactNode;
}

const TooltipPortal: React.FC<TooltipPortalProps> = ({
  targetRef,
  visible,
  offsetX = 0,
  offsetY = 0,
  children,
}) => {
  const [position, setPosition] = useState<{
    left: number;
    top: number;
  } | null>(null);

  useEffect(() => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({ left: rect.right + offsetX, top: rect.top + offsetY });
    }
  }, [targetRef, visible, offsetX, offsetY]);

  if (!visible || !position) return null;
  return createPortal(
    <div
      style={{ position: "fixed", left: position.left, top: position.top }}
      className="bg-white text-black dark:bg-black dark:text-white text-xs rounded-md px-3 py-2 shadow-md z-50 max-w-xs whitespace-normal"
    >
      {children}
    </div>,
    document.body
  );
};

// Function to capture form errors in Sentry
const captureFormError = (
  error: Error,
  contextData: Record<string, any> = {}
) => {
  // Extract error patterns for better classification
  const errorMessage = error?.message || "";
  const isNetworkError =
    errorMessage.includes("network") || errorMessage.includes("fetch");
  const isValidationError =
    errorMessage.includes("validation") || errorMessage.includes("invalid");
  const isQuoteError =
    errorMessage.includes("quote") || errorMessage.includes("price");
  const isSubmissionError =
    errorMessage.includes("submit") || errorMessage.includes("order");

  // Create error classification for better grouping in Sentry
  const errorType = isNetworkError
    ? "api.network_error"
    : isValidationError
      ? "api.validation_error"
      : isQuoteError
        ? "api.quote_error"
        : isSubmissionError
          ? "api.submission_error"
          : "api.other_error";

  // Capture with enriched context
  Sentry.captureException(error, {
    tags: {
      error_type: errorType,
      api_action: contextData.formAction || "unknown",
    },
    extra: {
      // Add minimal context data
      currency: contextData.currency,
      stablecoin: contextData.stablecoin,
      network: contextData.network,
    },
    level: "error",
  });

  // Also log to console for local debugging
  console.error(`Form Error (${errorType}):`, error, contextData);

  return errorType;
};

const BuyForm: React.FC<BuyFormProps> = ({
  setShowOrderSummary,
  setFormData,
}) => {
  const [formData, setformData] = useState({
    amount: "",
    currency: "",
    wallet: "",
    stablecoin: "",
    network: "",
    purpose: "",
    source: "",
  });
  const [stablecoinValue, setStablecoinValue] = useState<string | null>(null);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [quoteError, setQuoteError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [walletError, setWalletError] = useState("");
  const [stableTooltipVisible, setStableTooltipVisible] = useState(false);
  const [submitTooltipVisible, setSubmitTooltipVisible] = useState(false);
  const [wireTooltipVisible, setWireTooltipVisible] = useState(false);
  const [formSessionId, setFormSessionId] = useState<string>("");

  const stableTooltipRef = useRef<HTMLDivElement>(null);
  const submitTooltipRef = useRef<HTMLDivElement>(null);
  const wireTooltipRef = useRef<HTMLDivElement>(null);

  const currencyOptions = [
    { value: "CAD", label: "CAD" },
    { value: "USD", label: "USD" },
  ];

  const stablecoinOptions = [
    { value: "USDC", label: "USDC" },
    { value: "CADC", label: "CADC" },
  ];

  const networkOptions: Record<string, string[]> = {
    CADC: ["Base", "Arbitrum", "Ethereum", "Polygon"],
    USDC: [
      "Ethereum",
      "Solana",
      "BSC",
      "Polygon",
      "Base",
      "Arbitrum",
      "Optimism",
      "Avalanche",
      "Celo",
      "SX",
    ],
  };

  const networkMappings: Record<string, string> = {
    Ethereum: "ETH",
    Polygon: "POLY",
    Base: "BASE",
    Arbitrum: "ARB",
    Celo: "CELO",
    Avalanche: "AVAX",
    Binance: "BIN",
    Optimism: "OP",
    SX: "SX",
    Solana: "SOL",
  };

  const networkOptionsForStablecoin =
    formData.stablecoin && networkOptions[formData.stablecoin]
      ? networkOptions[formData.stablecoin].map((net) => ({
          value: networkMappings[net] || net,
          label: net,
        }))
      : [];

  const purposeOptionsSelect = [
    { value: "Business expenses", label: "Business expenses" },
    { value: "Payroll", label: "Payroll" },
    { value: "Loan repayment", label: "Loan repayment" },
    { value: "Savings", label: "Savings" },
    { value: "Real estate", label: "Real estate" },
    { value: "Gift", label: "Gift" },
    { value: "Other", label: "Other" },
  ];

  const sourceOptionsSelect = [
    { value: "Investment proceeds", label: "Investment proceeds" },
    { value: "Business revenue", label: "Business revenue" },
    { value: "Salary", label: "Salary" },
    { value: "Savings", label: "Savings" },
    { value: "Loan", label: "Loan" },
    { value: "Gift", label: "Gift" },
    { value: "Other", label: "Other" },
  ];

  const formatNumber = (value: string) => {
    const parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const validateWalletAddress = (address: string, network: string) => {
    if (!address) return "";
    if (!network) return "";
    if (network === "SOL" || network === "Solana") {
      const solRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
      if (!solRegex.test(address)) {
        return "Enter a valid Solana address";
      }
    } else {
      if (!isAddress(address)) {
        return `Enter a valid ${network} address`;
      }
    }
    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "amount") {
      newValue = value.replace(/[^0-9.]/g, "");
      const parts = newValue.split(".");
      if (parts.length > 2) {
        newValue = parts[0] + "." + parts[1];
      }
      if (parts.length === 2) {
        parts[1] = parts[1].slice(0, 2);
        newValue = parts[0] + "." + parts[1];
      }
      newValue = formatNumber(newValue);
    }
    if (name === "wallet") {
      const errorMessage = validateWalletAddress(newValue, formData.network);
      setWalletError(errorMessage);
    }
    setformData((prev) => ({ ...prev, [name]: newValue }));
  };

  useEffect(() => {
    const errorMessage = validateWalletAddress(
      formData.wallet,
      formData.network
    );
    setWalletError(errorMessage);
  }, [formData.wallet, formData.network]);

  const fetchPriceQuote = useCallback(
    async (amount: string) => {
      if (!amount) {
        setStablecoinValue(null);
        return;
      }
      const numericAmount = Number(amount.replace(/,/g, ""));
      if (
        numericAmount < 10000 ||
        !formData.stablecoin ||
        !formData.network ||
        !formData.currency
      ) {
        setStablecoinValue(null);
        return;
      }
      setQuoteLoading(true);
      setQuoteError("");
      try {
        const response = await getPriceQuote(
          numericAmount,
          formData.currency,
          `${formData.stablecoin}-${formData.network}`
        );
        setStablecoinValue(formatNumber(String(response.data.rightSideValue)));
      } catch (e) {
        // Capture the error in Sentry
        captureFormError(e as Error, {
          formAction: "fetchQuote",
          amount: numericAmount,
          currency: formData.currency,
          stablecoin: formData.stablecoin,
          network: formData.network,
          sessionId: formSessionId,
        });
        console.error(e);
        setQuoteError("Failed to fetch stablecoin value");
        setStablecoinValue(null);
      } finally {
        setQuoteLoading(false);
      }
    },
    [formData.stablecoin, formData.network, formData.currency]
  );

  useEffect(() => {
    const numericAmount = Number(formData.amount.replace(/,/g, ""));
    if (
      numericAmount >= 10000 &&
      formData.stablecoin &&
      formData.network &&
      formData.currency
    ) {
      fetchPriceQuote(formData.amount);
    } else {
      setStablecoinValue(null);
    }
  }, [
    formData.amount,
    formData.stablecoin,
    formData.network,
    formData.currency,
    fetchPriceQuote,
  ]);

  const isFormValid =
    formData.amount &&
    parseFloat(formData.amount.replace(/,/g, "")) >= 10000 &&
    formData.currency &&
    formData.wallet &&
    !walletError &&
    formData.stablecoin &&
    formData.network &&
    formData.purpose &&
    formData.source;

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await addOtcBuyOrder({
        amount: Number(formData.amount.replace(/,/g, "")) || 0,
        currency: formData.currency || "CAD",
        target_label:
          formData.stablecoin && formData.network
            ? `${formData.stablecoin}-${formData.network}`
            : "USDC-BASE",
        wallet: formData.wallet || "",
        purpose: formData.purpose || "",
        sourceOfFunds: formData.source || "",
      });
      void response;
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setFormData({ ...formData, currentTime });
      setShowOrderSummary(true);
    } catch (error) {
      setError("Failed to submit order. Please try again.");
      console.error(error);

      // Capture the error in Sentry
      captureFormError(error as Error, {
        formAction: "submitOrder",
        formData,
        sessionId: formSessionId,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-8 mx-auto md:mt-[-20px] md:ml-[-22px] mt-4">
      <div className="w-full md:w-1/2">
        <div className="p-4  border border-gray-300 dark:bg-white dark:text-black dark:border-0">
          <h3 className="text-3xl font-normal mb-4">Please send a wire to:</h3>
          <div className="space-y-4">
            <div className="flex justify-between">
              <span className="font-normal">Beneficiary Name:</span>
              <span>PayTrie AB Inc</span>
            </div>
            <div className="flex justify-right">
              <span className="font-normal mr-20">Beneficiary Address:</span>
              <span className=" text-right">
                500 4 Ave SW, Unit 2500, Calgary, AB, T2P 2V6
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-normal">Institution Number:</span>
              <span>219</span>
            </div>
            <div className="flex justify-between">
              <span className="font-normal">Transit Number:</span>
              <span>07509</span>
            </div>
            <div className="flex justify-between">
              <span className="font-normal">Account Number:</span>
              <span>
                {formData.currency
                  ? formData.currency === "USD"
                    ? "00505024379"
                    : "480898379"
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        {formData.currency && (
          <div className="mt-2 flex items-center">
            <button
              type="button"
              onClick={() =>
                window.open(
                  formData.currency === "USD"
                    ? "https://storage.paytrie.com/Paytrie_USD_Wire_Instructions_(Canadian_Bank).pdf"
                    : "https://storage.paytrie.com/Paytrie_CAD_Wire_Instructions.pdf",
                  "_blank"
                )
              }
              className="flex items-center justify-center px-4 py-2 bg-black text-white dark:bg-white dark:text-black border"
            >
              {formData.currency === "USD"
                ? "USD Wire Instructions"
                : "CAD Wire Instructions"}
            </button>
            <div
              ref={wireTooltipRef}
              className="ml-2"
              onMouseEnter={() => setWireTooltipVisible(true)}
              onMouseLeave={() => setWireTooltipVisible(false)}
              onTouchStart={() => setWireTooltipVisible(true)}
              onTouchEnd={() => setWireTooltipVisible(false)}
            >
              <InfoCircledIcon className="text-gray-500 cursor-pointer w-4 h-4" />
            </div>
            <TooltipPortal
              targetRef={wireTooltipRef}
              visible={wireTooltipVisible}
              offsetX={8}
              offsetY={-8}
            >
              Wire speeds may take 1-2 business days to process. We do not
              accept Wise (formerly TransferWise) transfers or inter-bank
              transfers.
            </TooltipPortal>
          </div>
        )}
      </div>

      <div className="w-full md:w-1/2 space-y-8">
        <div className="rounded-md">
          <h2 className="text-3xl font-normal mb-4">Pay</h2>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
            <label className="w-full md:w-48 font-normal">Amount:</label>
            <div className="w-full">
              <input
                type="text"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Enter amount"
                className="w-full border-b border-black dark:border-white bg-transparent dark:text-white outline-none pl-1 placeholder-gray-500"
              />
              {formData.amount &&
                parseFloat(formData.amount.replace(/,/g, "")) < 10000 && (
                  <span className="text-red-500 text-sm">
                    Minimum amount is $10,000
                  </span>
                )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4 w-full">
            <label className="w-full md:w-48 font-normal">Currency:</label>
            <div className="w-full">
              <RadixSelect.Root
                value={formData.currency || undefined}
                onValueChange={(value) =>
                  setformData((prev) => ({ ...prev, currency: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value
                    placeholder="Select currency"
                    className="text-gray-300"
                  />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {currencyOptions.map((option) => (
                        <RadixSelect.Item
                          key={option.value}
                          value={option.value}
                          className="cursor-pointer hover:bg-rock data-[highlighted]:bg-rock p-2"
                        >
                          <RadixSelect.ItemText>
                            <div className="flex items-center">
                              <img
                                src={currencyLogos[option.value]}
                                alt={`${option.label} logo`}
                                className="w-4 h-4 mr-2"
                              />
                              {option.label}
                            </div>
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
        </div>
        <div className="rounded-md">
          <h2 className="text-3xl font-normal mb-4">Receive</h2>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
            <label className="w-full md:w-48 font-normal">Stablecoin:</label>
            <div className="w-full">
              <RadixSelect.Root
                value={formData.stablecoin || undefined}
                onValueChange={(value) => {
                  setformData((prev) => {
                    const allowedNetworks = (networkOptions[value] || []).map(
                      (net) => networkMappings[net] || net
                    );
                    const currentNetworkAllowed =
                      prev.network && allowedNetworks.includes(prev.network);
                    const newNetwork = currentNetworkAllowed
                      ? prev.network
                      : "";
                    const newFormData = {
                      ...prev,
                      stablecoin: value,
                      network: newNetwork,
                    };
                    const numericAmount = Number(
                      newFormData.amount.replace(/,/g, "")
                    );
                    if (
                      numericAmount >= 10000 &&
                      newFormData.currency &&
                      newFormData.stablecoin &&
                      newFormData.network
                    ) {
                      setTimeout(() => {
                        fetchPriceQuote(newFormData.amount);
                      }, 0);
                    }
                    return newFormData;
                  });
                }}
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select stablecoin" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {stablecoinOptions.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="hover:bg-rock data-[highlighted]:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                        >
                          <RadixSelect.ItemText>
                            <div className="flex items-center">
                              <img
                                src={stablecoinLogos[opt.value]}
                                alt={`${opt.label} logo`}
                                className="w-4 h-4 mr-2"
                              />
                              {opt.label}
                            </div>
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
            <label className="w-full md:w-48 font-normal">Network:</label>
            <div className="w-full">
              <RadixSelect.Root
                value={formData.network || undefined}
                onValueChange={(value) =>
                  setformData((prev) => ({ ...prev, network: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select network" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {networkOptionsForStablecoin.map((option) => (
                        <RadixSelect.Item
                          key={option.value}
                          value={option.value}
                          className="cursor-pointer hover:bg-rock data-[highlighted]:bg-rock p-2"
                        >
                          <RadixSelect.ItemText>
                            <div className="flex items-center">
                              <img
                                src={networkLogos[option.label]}
                                alt={`${option.label} logo`}
                                className="w-4 h-4 mr-2"
                              />
                              {option.label}
                            </div>
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
            <label className="w-full md:w-48 font-normal">
              Wallet Address:
            </label>
            <div className="w-full">
              <input
                type="text"
                name="wallet"
                value={formData.wallet}
                onChange={handleChange}
                placeholder="Enter wallet address"
                className="w-full border-b border-black dark:border-white bg-transparent dark:text-white outline-none pl-1 placeholder-gray-500"
              />
              {walletError && (
                <p className="text-red-500 text-sm">{walletError}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
            <label className="w-full md:w-48 font-normal">
              Estimated Stablecoins:
            </label>
            <div className="w-full flex items-center ml-1">
              <span>
                {parseFloat(formData.amount.replace(/,/g, "")) >= 10000 ? (
                  quoteLoading ? (
                    "Fetching quote"
                  ) : quoteError ? (
                    <span className="text-gray-500 ml-1">{quoteError}</span>
                  ) : stablecoinValue ? (
                    <span className="dark:text-white text-black ml-1">
                      {stablecoinValue} {formData.stablecoin}-{formData.network}
                    </span>
                  ) : (
                    <span className="text-gray-500 ml-1">
                      Please fill all input fields
                    </span>
                  )
                ) : (
                  <span className="text-gray-500 md:ml-1">
                    Please fill all input fields
                  </span>
                )}
              </span>
              <div
                ref={stableTooltipRef}
                className="ml-2"
                onMouseEnter={() => setStableTooltipVisible(true)}
                onMouseLeave={() => setStableTooltipVisible(false)}
                onTouchStart={() => setStableTooltipVisible(true)}
                onTouchEnd={() => setStableTooltipVisible(false)}
              >
                <InfoCircledIcon className="text-gray-500 cursor-pointer w-4 h-4" />
              </div>
            </div>
          </div>
          <TooltipPortal
            targetRef={stableTooltipRef}
            visible={stableTooltipVisible}
            offsetX={8}
            offsetY={-8}
          >
            Your order will be processed at the latest market rate once we
            receive your wire.
          </TooltipPortal>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
            <label className="w-full md:w-48 font-normal">Purpose:</label>
            <div className="w-full">
              <RadixSelect.Root
                value={formData.purpose || undefined}
                onValueChange={(value) =>
                  setformData((prev) => ({ ...prev, purpose: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select purpose" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {purposeOptionsSelect.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="cursor-pointer hover:bg-rock data-[highlighted]:bg-rock p-2"
                        >
                          <RadixSelect.ItemText>
                            {opt.label}
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
            <label className="w-full md:w-48 font-normal">
              Source Of Funds:
            </label>
            <div className="w-full">
              <RadixSelect.Root
                value={formData.source || undefined}
                onValueChange={(value) =>
                  setformData((prev) => ({ ...prev, source: value }))
                }
              >
                <RadixSelect.Trigger className="py-2 inline-flex items-center justify-between w-full border-b border-black dark:border-white bg-transparent dark:text-white data-[placeholder]:text-gray-500 dark:data-[placeholder]:text-gray-500 outline-none pl-1">
                  <RadixSelect.Value placeholder="Select source" />
                  <RadixSelect.Icon className="text-gray-400">
                    <ChevronDownIcon />
                  </RadixSelect.Icon>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                  <RadixSelect.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
                    <RadixSelect.ScrollUpButton className="p-2.5">
                      <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport>
                      {sourceOptionsSelect.map((opt) => (
                        <RadixSelect.Item
                          key={opt.value}
                          value={opt.value}
                          className="cursor-pointer hover:bg-rock data-[highlighted]:bg-rock p-2"
                        >
                          <RadixSelect.ItemText>
                            {opt.label}
                          </RadixSelect.ItemText>
                        </RadixSelect.Item>
                      ))}
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className="p-2.5 text-gray-400">
                      <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                  </RadixSelect.Content>
                </RadixSelect.Portal>
              </RadixSelect.Root>
            </div>
          </div>
          <div className="mt-10 flex items-center">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!isFormValid || loading}
              className={`px-12 py-3 text-lg font-medium ${isFormValid && !loading ? "dark:bg-white dark:text-black bg-black text-white" : "bg-gray-400 text-gray-700 cursor-not-allowed"}`}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
            <div
              ref={submitTooltipRef}
              className="ml-2"
              onMouseEnter={() => setSubmitTooltipVisible(true)}
              onMouseLeave={() => setSubmitTooltipVisible(false)}
              onTouchStart={() => setSubmitTooltipVisible(true)}
              onTouchEnd={() => setSubmitTooltipVisible(false)}
            >
              <InfoCircledIcon className="text-gray-500 cursor-pointer w-4 h-4" />
            </div>
            <TooltipPortal
              targetRef={submitTooltipRef}
              visible={submitTooltipVisible}
              offsetX={8}
              offsetY={-8}
            >
              OTC is available Mon - Fri, 9am - 4pm EST, excluding bank
              holidays.
            </TooltipPortal>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default BuyForm;
