import { useEffect, useState } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

import { LoadingIcon } from "@/components/LoadingIcon";
import { Button } from "@/components/Button/Button";
import { usePageStateStore, useSignupStore } from "@/reducers";
import {
  FormattedKycQuestion,
  generateKycQuestions,
  submitKycAnswers,
} from "@/requests/signup";

export const SoftCheck = () => {
  const [kycQuestions, setKycQuestions] = useState<FormattedKycQuestion[]>([]);
  const [err, setErr] = useState("");
  const email = useSignupStore.use.email();
  const setFormState = useSignupStore.use.nextState();
  const [loading, setLoading] = useState(true);
  const [questionIndex] = useState(0); // DEV: questionIndex value is always 0
  const [answers, setAnswers] = useState<Map<number, string>>(new Map());
  const [txKey, setTxKey] = useState("");
  const setAuthToken = usePageStateStore.use.setAuthToken();

  useEffect(() => {
    async function loadQuestions(): Promise<void> {
      const [err, qs, txKey] = await generateKycQuestions(email);
      if (err !== null) {
        setErr(err);
      }
      setTxKey(txKey);
      setKycQuestions(qs);
      setLoading(false);
    }
    if (kycQuestions.length === 0) {
      loadQuestions();
    }
  }, []);

  if (loading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  const answerQuestion = (idx: number, val: string) => {
    setAnswers(() => {
      return new Map(answers).set(idx, val);
    });
  };

  const submitAnswers = async () => {
    try {
      setLoading(true);

      const token = await submitKycAnswers(
        email,
        txKey,
        Array.from(answers.values())
      );
      setAuthToken(token);
      setFormState();
    } catch {
      setErr("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submitAnswers}>
      <div className="flex flex-col gap-5">
        <h2 className="text-lg font-normal uppercase">Verify credentials</h2>
        <h3>{kycQuestions[questionIndex].question}</h3>
        <RadioGroup.Root
          key={questionIndex}
          className="flex flex-col gap-2.5"
          onValueChange={(value) => answerQuestion(questionIndex, value)}
        >
          {kycQuestions[questionIndex].answers.map((answer, index) => {
            const id = `id-r${index}`;
            return (
              <div className="flex items-center">
                <RadioGroup.Item
                  className="bg-white dark:bg-pebble w-[15px] h-[15px] rounded-full border border-pebble dark:border-white focus:shadow-[0_0_0_2px] focus:shadow-clementine dark:focus:shadow-sun outline-none cursor-default"
                  value={index.toString()}
                  id={id}
                >
                  <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[9px] after:h-[9px] after:rounded-[50%] after:bg-clementine dark:after:bg-sun" />
                </RadioGroup.Item>
                <label
                  className="text-[15px] leading-none pl-[15px]"
                  htmlFor={id}
                >
                  {answer}
                </label>
              </div>
            );
          })}
        </RadioGroup.Root>
        <div className="text-rose-600">{err}</div>
        {questionIndex === kycQuestions.length - 1 && (
          <Button type="submit" disabled={!answers.get(questionIndex)}>
            Submit
          </Button>
        )}
        {questionIndex < kycQuestions.length - 1 && (
          <Button type="submit" disabled={!answers.get(questionIndex)}>
            Next
          </Button>
        )}
      </div>
    </form>
  );
};
