import clsx from "clsx";
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";
import { styled } from "styled-components";

type ButtonOptions = {
  color?: "primary" | "secondary";
};

const StyledButton = styled.button`
  height: 40px;
  font-feature-settings: "ss01";
  transition: all 100ms;

  &:hover &:not(&:disabled) {
    opacity: 0.8;
  }

  &:active:not(:disabled) {
    transform: scale(0.98) translateZ(0);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  ButtonOptions;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const {
      type = "button",
      color = "primary",
      className,
      children,
      ...rest
    } = props;
    const merged = clsx(
      "uppercase font-normal text-sm items-center tracking-wide gap-5 text-black border-transparent border-solid border",
      {
        ["bg-black dark:bg-semiWhite text-snow dark:text-black disabled:bg-rock dark:disabled:bg-rock"]:
          color === "primary",
        ["bg-clementine dark:bg-sun  text-black"]: color === "secondary",
        ["bg-rock"]: rest.disabled,
      },
      className
    );

    return (
      <StyledButton type={type} className={merged} ref={ref} {...rest}>
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = "Button";
