import { styled } from "styled-components";

import InteracCircle from "@/assets/interac-circle.png";
import { Button } from "@/components/Button/Button";
import { PaytrieTopIcon } from "@/components/widget-components";
import { Pages, useBuySellStore, usePageStateStore } from "@/reducers";
import { SummaryRow } from "./SummaryRows";

type SellTransactionProps = {
  sourceWallet: string;
  orderId: string;
};

const SubHeader = styled.h4`
  font-weight: 400;
  margin-bottom: 4px;
`;

export const SellTransactionInstructions = ({
  sourceWallet,
  orderId,
}: SellTransactionProps) => {
  const goToPage = usePageStateStore.use.goToPage();
  const stableCoinAmount = useBuySellStore.use.stableCoinAmount();
  const stableCoin = useBuySellStore.use.stableCoin();
  return (
    <div className="flex flex-col gap-5">
      <div className="grid grid-cols-1 md:grid-cols-3 mb-3 flex-grow">
        <div className="md:col-span-3">
          <h1 className="uppercase text-2xl">Next Steps</h1>
          <p className="mb-3">
            Please send {stableCoin} {stableCoinAmount} to PayTrie(
            {import.meta.env.VITE_PAYTRIE_RECEIVING_WALLET.slice(0, 6)})
          </p>
        </div>
        <div>
          <SubHeader>Transaction ID</SubHeader>
          <div className="text-xs">{orderId}</div>
        </div>
        <div>
          <SubHeader>Receive</SubHeader>
          <div className="flex items-start">
            <img
              src={InteracCircle}
              className="mr-1 mt-1"
              height={15}
              width={15}
            />
            <div>
              <div className="font-normal">e-Transfer</div>
              <div className="text-sm">Interac</div>
            </div>
          </div>
        </div>
        <div>
          <SubHeader>From</SubHeader>
          <div className="text-xs">{`${sourceWallet.slice(
            0,
            4
          )}...${sourceWallet.slice(-4)}`}</div>
        </div>
        <div>
          <SubHeader>To</SubHeader>
          <div className="flex gap-2">
            <PaytrieTopIcon />{" "}
            <div>
              <div>Paytrie</div>
              <div className="text-xs">
                {import.meta.env.VITE_PAYTRIE_RECEIVING_WALLET}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SummaryRow />
      <Button onClick={() => goToPage(Pages.CALC)}>Start over</Button>
    </div>
  );
};
