import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import axiosInstance from "@/requests/axios";
import clsx from "clsx";

interface ValidationError {
  file?: string;
}

export default function CorporateArticleOfIncorporationUpload() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load state on component mount
  useEffect(() => {
    const savedState = localStorage.getItem("corporateArticleState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      // Note: We can only store the filename in localStorage
      if (parsed.fileName) {
        setTouched((prev) => ({ ...prev, file: true }));
      }
    }
  }, []);

  const isValidFileType = (file: File) => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  const validateFile = (file: File | null): string | undefined => {
    if (!file) {
      return "Please upload your Articles of Incorporation";
    }
    if (!isValidFileType(file)) {
      return "Invalid file type. Please upload a PDF, JPG, or PNG file";
    }
    if (file.size > 10 * 1024 * 1024) {
      // 10MB limit
      return "File size must be less than 10MB";
    }
    return undefined;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    setTouched((prev) => ({ ...prev, file: true }));

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      const error = validateFile(droppedFile);
      if (error) {
        setErrors({ file: error });
      } else {
        setFile(droppedFile);
        setErrors({});
      }
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setTouched((prev) => ({ ...prev, file: true }));

    if (selectedFile) {
      const error = validateFile(selectedFile);
      if (error) {
        setErrors({ file: error });
      } else {
        setFile(selectedFile);
        setErrors({});
      }
    }
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as string));
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const handleDocumentKeyDown = async (e: KeyboardEvent) => {
      const isFormComplete = file && !isSubmitting;

      if (e.key === "Enter" && isFormComplete) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [file, isSubmitting]);

  const handleSubmit = async () => {
    if (isSubmitting || !file) return;

    setIsSubmitting(true);

    setTouched({ file: true });
    const error = validateFile(file);

    if (error) {
      setErrors({ file: error });
      setIsSubmitting(false);
      return;
    }

    try {
      if (!file) {
        setErrors({
          file: "Please upload a valid articles of incorporation",
        });
        setIsSubmitting(false);
        return;
      }

      // Get the previous state with cert of incorporation
      const previousState = localStorage.getItem("corporateIssuerState");
      if (!previousState) {
        setErrors({
          file: "Previous document information not found. Please go back and reupload your Certificate of Incorporation.",
        });
        setIsSubmitting(false);
        return;
      }

      const certOfIncorpData = JSON.parse(previousState);

      // Get the base64 data from sessionStorage using the stored reference
      let certFileBase64 = null;
      if (certOfIncorpData.certOfIncorpFile.tempStorageId) {
        certFileBase64 = sessionStorage.getItem(
          certOfIncorpData.certOfIncorpFile.tempStorageId
        );

        if (!certFileBase64) {
          setErrors({
            file: "Certificate file data not found. Please go back and reupload your Certificate of Incorporation.",
          });
          setIsSubmitting(false);
          return;
        }

        // Format the base64 if needed
        if (!certFileBase64.includes("data:")) {
          certFileBase64 = `data:${certOfIncorpData.certOfIncorpFile.type};base64,${certFileBase64}`;
        }
      }

      // Convert current file to base64 for API
      const currentFileBase64 = await getBase64(file);
      const formattedBase64 = currentFileBase64.includes("data:")
        ? currentFileBase64
        : `data:${file.type};base64,${currentFileBase64}`;

      // Get corporation_email
      const corporateInfoState = localStorage.getItem("corporateInfoState");
      const corporateInfo = corporateInfoState
        ? JSON.parse(corporateInfoState)
        : {};
      const corporateEmail =
        corporateInfo.corporateEmail?.toLowerCase() ||
        localStorage.getItem("email")?.toLowerCase();

      // Create the cert file object with the retrieved base64
      const certOfIncorpFile = {
        uid: 1,
        name: certOfIncorpData.certOfIncorpFile.name,
        type: certOfIncorpData.certOfIncorpFile.type,
        size: certOfIncorpData.certOfIncorpFile.size,
        status: "done",
        url: certFileBase64,
        thumbUrl: certFileBase64,
      };

      // Create the article file object
      const articleFile = {
        uid: 2,
        name: file.name,
        type: file.type,
        size: file.size,
        status: "done",
        url: formattedBase64,
        thumbUrl: formattedBase64,
      };

      // Create arrays exactly as in the old code
      const certOfIncorpFileList = [certOfIncorpFile];
      const articlesOfIncorpFileList = [articleFile];

      const arraysList = certOfIncorpFileList.concat(articlesOfIncorpFileList);

      // Send to API
      const response = await axiosInstance({
        method: "post",
        url: "/uploadCorporateDocs",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          corporation_email: corporateEmail,
          corporation_base64_url: arraysList,
          incorpCertData: certOfIncorpData.verification,
        },
      });

      // Clean up sessionStorage after successful API call
      if (certOfIncorpData.certOfIncorpFile.tempStorageId) {
        sessionStorage.removeItem(
          certOfIncorpData.certOfIncorpFile.tempStorageId
        );
      }

      if (response.status === 200) {
        nextState();
        navigate(ROUTES.CORPORATE_ASSIST);
      } else {
        setErrors({
          file: response.data || "ERROR",
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
      setErrors({
        file: "Unfortunately we were unable to process your documents at this time. Please try again later or contact us at support@paytrie.com",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="text-lg font-normal uppercase">CORPORATE DOCUMENTS</div>

      <div className="flex flex-col gap-2">
        <p className="text-base">Articles of Incorporation</p>

        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`
            border-2 border-dashed rounded-lg p-8
            flex flex-col items-center justify-center
            cursor-pointer transition-all
            ${isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"}
            ${file ? "bg-green-50" : "hover:bg-gray-50"}
            ${touched.file && errors.file ? "border-red-500" : ""}
          `}
          onClick={() => document.getElementById("file-upload")?.click()}
        >
          <input
            id="file-upload"
            type="file"
            className="hidden"
            onChange={handleFileSelect}
            accept=".pdf,.jpg,.jpeg,.png"
          />
          {file ? (
            <div className="flex flex-col items-center">
              <p className="text-green-600 font-medium">{file.name}</p>
              <p className="text-sm text-gray-500 mt-1">Click to change file</p>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <p className="font-medium">Upload Articles of Incorporation</p>
              <p className="text-sm text-gray-500 mt-1">
                Drag and drop or click to select a file
              </p>
              <p className="text-xs text-gray-400 mt-1">
                Supported formats: PDF, JPG, PNG (Max 10MB)
              </p>
            </div>
          )}
        </div>
        {touched.file && errors.file && (
          <span className="text-sm text-red-500 mt-1">{errors.file}</span>
        )}
      </div>

      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !file}
        className={clsx(
          "mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2",
          {
            "opacity-50 cursor-not-allowed bg-gray-400": !file || isSubmitting,
            "hover:bg-gray-500": !file || isSubmitting,
          }
        )}
      >
        {isSubmitting ? "Processing..." : "Continue"}
      </Button>
    </div>
  );
}
