import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThickArrowLeftIcon, ThickArrowRightIcon } from "@radix-ui/react-icons";

import { getLoggedInUser, getTransactionsByUser } from "@/requests/user";
import { usePageStateStore } from "@/reducers/";
import { TransactionRow } from "./TransactionRows";
import { ReportButton } from "./ReportButton";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

export const DashboardHistory = () => {
  const authToken = usePageStateStore.use.authToken() || "";
  const [transactions, setTransactions] = useState([] as any);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 5;
  const navigate = useNavigate();

  const fetchTransactions = () => {
    getTransactionsByUser(email, authToken).then((response) => {
      setTransactions(response.data);
    });
  };

  useEffect(() => {
    async function fetchUser() {
      const { response, error } = await getLoggedInUser(authToken);

      if (response) {
        setFirstName(response.data.first_name);
        setEmail(response.data.email);
      } else if (error) {
        console.warn("Unable to fetch user");
        navigate("/EasyLogin");
      }
    }

    fetchUser();
  }, []);

  useEffect(() => {
    if (email.length > 0) {
      fetchTransactions();
    }
  }, [email]);

  const totalPages = Math.ceil(transactions.length / transactionsPerPage);

  const currentTransactions = transactions.slice(
    (currentPage - 1) * transactionsPerPage,
    currentPage * transactionsPerPage
  );

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div className="relative flex min-h-screen flex-col md:flex-row">
      <Topbar />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-70 z-30"
          onClick={toggleSidebar}
        ></div>
      )}
      <div className="flex-grow bg-cloud dark:bg-pebble p-6 relative z-10">
        <div className="bg-cloud dark:bg-pebble rounded-lg flex-grow pt-16 lg:min-w-[1050px] lg:ml-[-155px] pb-20">
          <div className="flex flex-row items-center justify-between py-2">
            <h1 className="text-3xl font-normal text-left mb-8 text-black dark:text-white">
              Transaction History
            </h1>
            <div className="mb-8 lg:text-base text-xs">
              <ReportButton transactions={transactions} firstName={firstName} />
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-cloud dark:bg-pebble border-collapse">
              <thead>
                <tr>
                  <th className="py-2 lg:text-xl sm:text-sm border-b-2 border-gray-300 dark:border-gray-700 text-left dark:text-gray-300">
                    Transaction ID
                  </th>
                  <th className="py-2 lg:text-xl sm:text-sm px-7 border-b-2 border-gray-300 dark:border-gray-700 sm:text-left text-center dark:text-gray-300">
                    Status
                  </th>
                  <th className="py-2 lg:text-xl sm:text-sm border-b-2 border-gray-300 dark:border-gray-700 text-center dark:text-gray-300">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTransactions.map((t: any, index: number) => (
                  <TransactionRow
                    key={index}
                    index={index}
                    transactionId={t.tx_id}
                    status={t.status}
                    amount={t.rightSideValue}
                    rightSideLabel={t.rightSideLabel}
                    rightSideValue={t.rightSideValue}
                    leftSideLabel={t.leftSideLabel}
                    leftSideValue={t.leftSideValue}
                    date={t.date}
                    wallet={t.wallet}
                    paymentId={t.payment_id}
                    refreshTransactions={fetchTransactions}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <footer className="w-full bg-cloud dark:bg-pebble p-4 flex flex-col items-center fixed bottom-0 left-0 z-20">
          <div className="flex justify-center items-center space-x-4 mb-2">
            <button
              onClick={handlePreviousPage}
              className="p-2 rounded-full disabled:opacity-50"
              disabled={currentPage === 1}
            >
              <ThickArrowLeftIcon className="w-6 h-6 text-gray-900 dark:text-gray-100" />
            </button>
            <span className="mx-4 text-gray-900 dark:text-gray-100">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              className="p-2 rounded-full disabled:opacity-50"
              disabled={currentPage === totalPages}
            >
              <ThickArrowRightIcon className="w-6 h-6 text-gray-900 dark:text-gray-100" />
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default DashboardHistory;
